// Dependencies
import { graphql } from 'gatsby';
import React from 'react';
import Fade from 'react-reveal';
import styled from 'styled-components';

// Components
import Map from '../components/map';
import SEO from '../components/seo';
import HowToGetIcon from '../components/svg/jak_dojechac';
import { Container } from './index';
import { PageTitle } from './pakiety';

const HowToGet = ({ data }) => {
  const howToGet = data.cms.howToGetPages[0].content;

  return (
    <>
      <StyledFade>
        <Container>
          <PageTitle>{'jak do nas trafić'}</PageTitle>
          <Content>{howToGet.description}</Content>
          <div style={{ marginBottom: '30px', marginTop: '30px' }}>
            <SmallHeader>{howToGet.navigation}</SmallHeader>
            <ButtonWrapper>
              <StyledButton
                href={howToGet.navigateWithGoogleButtonHref}
                target="_blank"
              >
                <HowToGetIcon />
                {howToGet.navigateWithGoogleButtonText}
              </StyledButton>
            </ButtonWrapper>
            <Content>
              {howToGet.navigationDesc.map((c, index) => (
                <p key={index}>{c}</p>
              ))}
            </Content>
          </div>
          <div style={{ marginBottom: '30px', marginTop: '30px' }}>
            <SmallHeader>{howToGet.byCar}</SmallHeader>
            <Content>{howToGet.byCarDesc}</Content>
          </div>
          <div style={{ marginBottom: '60px', marginTop: '30px' }}>
            <SmallHeader>{howToGet.localTransport}</SmallHeader>
            <Content>
              {howToGet.localTransportDesc.map((c, index) => (
                <p key={index}>{c}</p>
              ))}
            </Content>
          </div>
        </Container>
        <Map />
        <SEO />
      </StyledFade>
    </>
  );
};

export default HowToGet;

const StyledFade = styled(Fade)`
  display: flex;
  justify-content: center;
`;

const SmallHeader = styled.h3`
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  margin-bottom: ${(props) => props.theme.space[2]}px;
`;

const Content = styled.p`
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  p {
    margin: ${(props) => props.theme.space[3]}px 0;
  }
`;

const StyledButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.theme.fontSizes[1]}px;
  border: 2px solid ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.primary};
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  padding: ${(props) => props.theme.space[3]}px
    ${(props) => props.theme.space[6]}px;
  position: relative;
  overflow: hidden;
  transition: 0.4s ease;
  z-index: 2;
  @media screen and (max-width: ${(props) => props.theme.breakpoints[2]}) {
    width: 100%;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints[3]}) {
    &::after {
      content: '';
      width: 0;
      height: 100%;
      transform: skew(-25deg);
      background: ${(props) => props.theme.colors.primary};
      position: absolute;
      top: 0;
      left: -10%;
      transition: 0.3s ease;
      z-index: -1;
    }
    &:hover {
      color: ${(props) => props.theme.colors.white};
      &::after {
        width: 120%;
      }
      svg {
        fill: #fff;
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${(props) => props.theme.space[5]}px 0;
`;

export const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          howToGetPage {
            pageTitle
            description
            localTransport
            localTransportDesc
            byCar
            byCarDesc
            navigation
            navigationDesc
          }
        }
      }
    }
    cms {
      howToGetPages {
        content
      }
    }
  }
`;
