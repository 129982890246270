import React from "react";
import styled from "styled-components";

const HowToGet = () => {
  return (
    <StyledSVG width="100%" height="100%" viewBox="0 0 596 842" version="1.1">
      <g>
        <path d="M569.471,701.302L495.061,527.679C493.111,523.113 488.63,520.158 483.665,520.158L373.108,520.158L450.469,379.356C464.07,354.165 471.263,325.668 471.263,296.928C471.263,201.191 393.378,123.305 297.64,123.305C201.902,123.305 124.017,201.191 124.017,296.928C124.017,325.668 131.212,354.164 144.86,379.428L222.177,520.158L111.615,520.158C106.649,520.158 102.168,523.113 100.219,527.679L25.809,701.302C24.162,705.129 24.561,709.525 26.851,713.013C29.152,716.489 33.04,718.585 37.206,718.585L558.076,718.585C562.242,718.585 566.13,716.49 568.431,713.014C570.719,709.526 571.119,705.13 569.471,701.302ZM166.635,367.559C154.984,345.989 148.82,321.562 148.82,296.928C148.82,214.864 215.576,148.108 297.64,148.108C379.704,148.108 446.46,214.864 446.46,296.928C446.46,321.562 440.296,345.99 428.693,367.487L297.64,606.013L166.635,367.559ZM56.013,693.782L119.789,544.962L235.802,544.962L286.775,637.744C288.943,641.717 293.109,644.175 297.639,644.175C302.169,644.175 306.334,641.716 308.503,637.744L359.48,544.962L475.49,544.962L539.266,693.782L56.013,693.782Z" />
        <path d="M396.853,296.928C396.853,242.223 352.345,197.715 297.64,197.715C242.935,197.715 198.427,242.223 198.427,296.928C198.427,351.633 242.935,396.141 297.64,396.141C352.345,396.141 396.853,351.633 396.853,296.928ZM223.23,296.928C223.23,255.896 256.608,222.518 297.64,222.518C338.672,222.518 372.05,255.896 372.05,296.928C372.05,337.96 338.672,371.338 297.64,371.338C256.608,371.338 223.23,337.96 223.23,296.928Z" />
      </g>
    </StyledSVG>
  );
};

export default HowToGet;

const StyledSVG = styled.svg`
  width: 30px;
  height: 30px;
  fill: #ae541e;
  transition: 0.3s ease;
`;
