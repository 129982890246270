// Dependencies
import React, { useEffect } from "react";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";
import Img from "gatsby-image";

const center = {
  lat: 52.26,
  lng: 21.08,
};
let zoom = 12;

const createMapOptions = (maps) => {
  return {
    disableDefaultUI: true,
    zoomControl: true,
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_TOP,
      style: maps.ZoomControlStyle.SMALL,
    },
    styles: mapStyles,
  };
};

const Map: React.FC = () => {
  useEffect(() => {
    zoom = window.innerWidth > 1024 ? 12 : 13;
  }, []);
  return (
    <MapWrapper>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyD3QlfZ-W7moXsRlWR7WdfoxJURgxLVl6M" }}
        defaultCenter={center}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        options={createMapOptions}
      >
        <MarkerWrapper lat={52.26171} lng={21.0845}>
          <StyledText>Tu nas znajdziesz</StyledText>
          <StyledMarker src={require("../../src/images/pin.svg")} />
        </MarkerWrapper>
      </GoogleMapReact>
      <GoogleIconLink
        href="https://www.google.com/maps/place/Strzelnica+B7/@52.2617131,21.0823153,17z/data=!3m1!4b1!4m5!3m4!1s0x471ecdf5db85afd1:0x3eb7135f36a295dd!8m2!3d52.2617098!4d21.084504_"
        target="_blank"
      />
    </MapWrapper>
  );
};

export default Map;

const MapWrapper = styled.div`
  position: relative;
  height: 400px;
  width: 100%;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[3]}) {
    height: 644px;
  }
`;

const GoogleIconLink = styled.a`
  position: absolute;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 70px;
  height: 40px;
`;

const MarkerWrapper = styled.div<{ lat: number; lng: number }>`
  display: flex;
`;

const StyledText = styled.div`
  position: absolute;
  width: 190px;
  font-size: ${(props) => props.theme.fontSizes[4]}px;
  letter-spacing: 0.1rem;
  transform: translateX(-50%);
  top: -90px;
  left: 0;
`;

const StyledMarker = styled.img`
  height: 50px;
  transform: translate(-50%, -100%);
`;

const Stripes = styled(Img)`
  display: none;
  z-index: -1;

  @media screen and (min-width: 1400px) {
    display: inline-block;
    top: 0;
    right: 0;
    height: 700px;
    transform: translateY(-100%);
  }
`;

const mapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#181818",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#1b1b1b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#2c2c2c",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8a8a8a",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#373737",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#3c3c3c",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [
      {
        color: "#4e4e4e",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#3d3d3d",
      },
    ],
  },
];
